<div class="question">
  <ion-label class="ion-text-wrap">
    {{ model.questionResolved }}
  </ion-label>
  <div>
    <ion-button
      [disabled]="model.isDisabled"
      [class.answerRequired]="model.highlight && currentValue == null && initDate == null"
      fill="outline"
      (click)="openDatePicker()"
    >
      {{ initDate ? formatDate(initDate) :'LESSON.DATE_CHOOSE_MSG' | translate }}
    </ion-button>
  </div>
</div>

<ion-modal [keepContentsMounted]="true" [isOpen]="showCalendar" (didDismiss)="closeDatePicker()">
  <ng-template>
    <ion-datetime
      [disabled]="model.isDisabled"
      [showDefaultButtons]="true"
      [doneText]="translateService.instant('BUTTONS.OK')"
      [cancelText]="translateService.instant('BUTTONS.CANCEL')"
      first-day-of-week="1"
      [locale]="locale"
      [presentation]="presentation"
      (ionChange)="emitAnswer()"
      [(ngModel)]="selectedDateTime"
      [value]="initDate">
    </ion-datetime>
  </ng-template>
</ion-modal>
