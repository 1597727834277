import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AutosizeModule } from 'ngx-autosize';
import { IonicStorageModule } from '@ionic/storage-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FCM } from '@awesome-cordova-plugins/fcm/ngx'
// import { AngularFireModule } from '@angular/fire/compat';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from "./components/components.module";
import { AlertService } from './providers/alert/alert.service';
import { FirebaseService } from './providers/firebase/firebase.service';
import { HelperService } from "./providers/helper/helper.service";
import { LocalStorageService } from './providers/local-storage/local-storage.service';
import { ParserService } from "./providers/parser/parser.service";
import { RequestProviderService } from './providers/request-provider/request-provider.service';
import { TokenInterceptorService } from "./providers/token-interceptor/token-interceptor.service";
import { environment } from '../environments/environment';
import { RequestHelperService } from './providers/request-helper/request-helper.service';
import { PermissionManager } from './components/permission-manager/permission-manager.component';
import { SensingService } from './providers/sensing-service/sensing-service.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        CommonModule,
        BrowserModule,
        ComponentsModule,
        HttpClientModule,
        IonicModule.forRoot({ swipeBackEnabled: false }),
        // AngularFireModule.initializeApp(firebaseConfig),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        AutosizeModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        BrowserAnimationsModule,
        FontAwesomeModule,
    ],
    providers: [
        StatusBar,
        HttpClientModule,
        SplashScreen,
        File,
        HttpClientModule,
        FCM,
        AlertService,
        FirebaseService,
        HelperService,
        LocalStorageService,
        ParserService,
        RequestProviderService,
        TokenInterceptorService,
        RequestHelperService,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
        PermissionManager,
        SensingService,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule {
  public darkTheme = true;
  constructor() { }
}
