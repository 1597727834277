import { Injectable } from "@angular/core";
import { RequestProviderService } from "../request-provider/request-provider.service";
import { LocalStorageService } from "../local-storage/local-storage.service";
import { ParserService } from "../parser/parser.service";
import { TokenInterceptorService } from "../token-interceptor/token-interceptor.service";
import { AlertService } from "../alert/alert.service";
import { NavController } from "@ionic/angular";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class RequestHelperService {
  constructor(
    private requestProvider: RequestProviderService,
    private localStorage: LocalStorageService,
    private parserService: ParserService,
    private tokenInterceptorService: TokenInterceptorService,
    private alertService: AlertService,
    private navCtrl: NavController,
  ) { }

  removeDeviceTokenIdAndLogout() {
    const id = this.localStorage.getDeviceTokenId();
    if (id !== -1) {
      this.requestProvider.deleteDeviceToken(id).subscribe({
        next: (_response) => {
          this.localStorage.removeDeviceTokenId();
          this.logout();
        },
        error: (_error) => {
          this.localStorage.removeDeviceTokenId();
          this.logout();
        },
      });
    } else {
      this.logout();
    }
  }

  logout() {
    this.requestProvider.logout().subscribe({
      next: (_response) => {
        this.tokenInterceptorService.cancelPendingRequests();
        this.localStorage.removeToken();
        this.localStorage.removeUserEmail();
        this.localStorage.removeUserId();
        this.parserService.newMessages = 0;
        this.parserService.newFeedback = 0;
        this.parserService.showAnnouncements = false;
        this.parserService.selectedAnnouncement = 0;
        this.navCtrl.navigateRoot("/login", {
          animated: true,
          animationDirection: "forward",
        }); //use this to reset root so that no data of previously logged in user is still there todorouting
        // this.router.navigate(['/login'], {replaceUrl: true});
      },
      error: (error) => {
        if (
          JSON.stringify(error) == '"no internet connection."' ||
          (error instanceof HttpErrorResponse && error.status == 401)
        ) {
          this.localStorage.removeToken();
          this.localStorage.removeUserEmail();
          this.localStorage.removeUserId();
          this.navCtrl.navigateRoot("/login", {
            animated: true,
            animationDirection: "forward",
          }); //use this to reset root so that no data of previously logged in user is still there todorouting
        } else {
          this.alertService.showError(
            "ERROR.ERROR",
            "ERROR.ERROR_SOMETHING_WENT_WRONG",
          );
        }
      },
    });
  }
}
