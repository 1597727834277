<div [class.required]="model.required" class="ion-text-left question">
  <div>
    <ion-label class="ion-text-wrap ion-text-left">
      {{ model.questionResolved }}
    </ion-label>
  </div>
  <ion-input
    type="text"
    placeholder=""
    [value]="currentValue"
    [class.answerRequired]="
      model.highlight && (currentValue == null || currentValue == '')
    "
    [attr.readonly]="model.isDisabled"
    (input)="emitAnswer($event.target.value)"
  ></ion-input>
</div>
