import { Component, EventEmitter, Output } from "@angular/core";
import { MenuController, ModalController, Platform } from "@ionic/angular";
import { SplashScreen } from "@awesome-cordova-plugins/splash-screen/ngx";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import { Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { NavigationEnd } from "@angular/router";
import { register } from "swiper/element/bundle";
import { TechnicalSupportComponent } from "./components/dialogs/technicalsupport/technical-support.component";
import { AlertService } from "./providers/alert/alert.service";
import { ParserService } from "./providers/parser/parser.service";
import { LocalStorageService } from "./providers/local-storage/local-storage.service";
import { forkJoin } from "rxjs";
import { RequestProviderService } from "./providers/request-provider/request-provider.service";

// Globally register Swiper custom elements
register();

const noSideMenuURLs = [
  "/login",
  "/forgot-password",
  "/app-imprint",
  "/app-privacy",
  "/landing",
] as const;

type SideMenuNavigation = {
  title: string;
  url: string;
  icon: string;
  id: string;
};

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  navigate: SideMenuNavigation[];
  previousUrl: string;
  smallScreen: boolean = false;
  private sideMenuDataNotLoaded = true;
  @Output("routeEmitter") routeEmitter = new EventEmitter<string>();

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private menuCtrl: MenuController,
    private alertService: AlertService,
    private translateService: TranslateService,
    private modalController: ModalController,
    private parserService: ParserService,
    private localStorage: LocalStorageService,
    private requestProvider: RequestProviderService,
  ) {
    this.sideMenu();
    this.initializeApp();
    this.registerNoMenuPages();
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = event.url;
        if (this.sideMenuDataNotLoaded) {
          const currentUrl = event.url;
          if (currentUrl.startsWith("/home")) {
            // "/home" always sets data for side menu
            this.sideMenuDataNotLoaded = false;
          } else {
            const previousNavigation = router.getCurrentNavigation().previousNavigation;
            const previousUrl = previousNavigation?.initialUrl.toString();
            // previousNavigation === null: After refreshing page or directly accessing page while logged in
            // previousUrl.startsWith(...): After refreshing page or directly accessing page while logged out, and then forwarded from login-page
            if (
              (previousNavigation === null || previousUrl.startsWith("/login")) &&
              this.urlHasSideMenu(currentUrl)
            ) {
              this.loadSideMenuState(currentUrl);
            }
          }
        }
      });
  }

  loadSideMenuState(currentUrl: string) {
    const reqs = {
      interventions: this.requestProvider.getMyInterventions(),
      diaryInstances: this.requestProvider.getMyDiaryInstances(),
      instances: this.requestProvider.getMyInstances(),
      messages: this.requestProvider.getMyMessages(),
      feedback: this.requestProvider.getMyFeedback(),
      announcements: this.requestProvider.getAnnouncements(),
      activities: this.requestProvider.getMyActivities(),
      invitations: this.requestProvider.getMyInvitations(),
    };
    forkJoin(reqs).subscribe({
      next: async (results) => {
        this.parserService.parseDiaryInstances(results.diaryInstances, true);
        const interventions = this.parserService.parseInterventions(results.interventions);
        this.parserService.parseInstances(results.instances, interventions);
        this.parserService.parseThreads(results.messages, "messages");
        this.parserService.parseThreads(results.feedback, "feedback");
        const invitations = this.parserService.parseInvitations(results.invitations);
        const newAnnouncements = this.parserService.parseAnnouncements(results.announcements);
        //if there is a new announcement select that & show announcement even if currently hidden by user
        //otherwise show currently selected one, except on login (first)
        if (newAnnouncements.length > this.parserService.announcements.length) {
          this.parserService.selectedAnnouncement = newAnnouncements.length - 1;
          this.parserService.showAnnouncements = true;
        } else if (newAnnouncements.length === 0) {
          this.parserService.selectedAnnouncement = 0;
          this.parserService.showAnnouncements = false;
        }
        this.parserService.announcements = newAnnouncements;
        this.parserService.parseActivities(results.activities);
        if (invitations.length > 0) {
          if (!currentUrl.startsWith("/invitations")) {
            this.alertService.showOpenInvitations(this.router);
          } else {
            this.alertService.openInvitationsNeverShown = false;
          }
        }
        this.sideMenuDataNotLoaded = false;
      },
      error: (_error) => { },
    });
  }

  initializeApp() {
    document.body.classList.toggle("darkTheme", true);
    this.translateService.setDefaultLang(this.localStorage.getAppLanguage());

    this.platform.ready().then(() => {
      if (this.platform.is("android") || this.platform.is("ios")) {
        this.statusBar.styleDefault();
        this.splashScreen.hide();
      }

      if (this.platform.width() < 992) {
        this.smallScreen = true;
      }

      this.platform.resize.subscribe(() => {
        if (this.platform.width() < 992) {
          this.smallScreen = true;
        } else {
          this.smallScreen = false;
        }
      });
    });
  }

  sideMenu() {
    this.navigate = [
      {
        title: "PAGES.HOME",
        url: "/home",
        icon: "home-outline",
        id: "navHome", //dont change ids!! used for testing
      },
      {
        title: "PAGES.MY_INTERVENTIONS",
        url: "/intervention-overview",
        icon: "clipboard-outline",
        id: "navInterventions",
      },
      {
        title: "PAGES.MY_DIARIES",
        url: "/diary-overview",
        icon: "book-outline",
        id: "navDiaries",
      },
      {
        title: "PAGES.CHAT",
        url: "/feedback-overview",
        icon: "people-outline",
        id: "navChat",
      },
      // {
      //   title: "PAGES.OPEN_INTERVENTIONS",
      //   url: "/open-interventions",
      //   icon: "globe"
      // },
      {
        title: "PAGES.INVITATIONS",
        url: "/invitations",
        icon: "paper-plane-outline",
        id: "navInvitations",
      },
      {
        title: "PAGES.MY_PROGRESS",
        url: "/progress",
        icon: "podium-outline",
        id: "navProgress",
      },
      {
        title: "PAGES.MY_PROFILE",
        url: "/settings",
        icon: "person-outline",
        id: "navProfile",
      },
    ];
  }

  registerNoMenuPages() {
    this.router.events.subscribe((event) => {
      if (this.localStorage.getIsInAppBrowser()) {
        this.menuCtrl.enable(false);
      } else if (event instanceof NavigationEnd) {
        const url = event.urlAfterRedirects;
        const showMenu = this.urlHasSideMenu(url);
        this.menuCtrl.enable(showMenu);
      }
    });
  }

  private urlHasSideMenu(url: string): boolean {
    return url !== "/" && noSideMenuURLs.every((u) => !url.startsWith(u));
  }

  routeToUrl(url: string, event: Event) {
    event.stopPropagation();
    if (this.previousUrl.includes("/lesson/")) {
      this.routeEmitter.emit(url);
    } else {
      this.router.navigateByUrl(url);
    }
    this.menuCtrl.close();
  }

  closeMenu() {
    this.menuCtrl.close();
  }

  showPage(url: string): boolean {
    if (url === "/diary-overview") {
      return this.parserService.showDiaries;
    } else if (url === "/progress") {
      return this.parserService.showProgress;
    } else {
      return true;
    }
  }

  async openEmailSupport() {
    const modal = await this.modalController.create({
      component: TechnicalSupportComponent,
      cssClass: "techModal",
    });
    return await modal.present();
  }
}
