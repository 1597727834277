/**
 * This data class holds all sensory information (location and pedometer) that is transmitted to the backend.
 */
export class SensoryInformation {
    /** The version of the `aas2/aas2-sensing-service` plugin that is used by the application. */
    sensing_service_version: string;

    /** Object with pedometer information or `null` if no pedometer information has been requested from a diary. */
    pedometer: PedometerInformation | null;

    /** Object with location information or `null` if no location information has been requested from a diary. */
    location: LocationInformation | null;

    /**
     * @param sensingServiceVersion - The version of the sensing service.
     * @param pedometerInformation - Pedometer information object or `null` if no pedometer information has been requested from a diary.
     * @param locationInformation - Location information object or `null` if no location information has been requested from a diary.
     */
    constructor(sensingServiceVersion: string, pedometerInformation: PedometerInformation | null, locationInformation: LocationInformation | null) {
        this.sensing_service_version = sensingServiceVersion;
        this.pedometer = pedometerInformation;
        this.location = locationInformation;
    }
}

/**
 * This data class holds all relevant pedometer information.
 */
export class PedometerInformation {
    /**
     * Contains the status of the pedometer data request:
     * 
     * - `"success"` - if no error has occurred
     * - If a `aas2/aas2-sensing-service`-specific error has occurred: The `code` attribute of the error.
     * - If an unspecific error has occurred: `"unspecified-error"`
     */
    status: string;

    /**
     * If no error has occurred, the value is `null`; otherwise, it contains a description of the thrown error.
     */
    error_message: string | null;

    /**
     * Contains a timestamp (in epoch millis) of the time interval in which the steps were taken.
     * If an error has occurred, the value is `null`.
     */
    collected_at: number | null;

    /**
     * Contains the number of steps taken. If an error has occurred, the value is `null`.
     */
    steps: number | null;

    /**
     * @param status - The status of the pedometer data request.
     * @param errorMessage - The error message if an error occurred, otherwise `null`.
     * @param collectedAt - The timestamp when the steps were taken, otherwise `null`.
     * @param steps - The number of steps taken, otherwise `null`.
     */
    constructor(status: string, errorMessage: string | null, collectedAt: number | null, steps: number | null) {
        this.status = status;
        this.error_message = errorMessage;
        this.collected_at = collectedAt;
        this.steps = steps;
    }
}

/**
 * This data class holds all relevant location information.
 */
export class LocationInformation {
    /**
     * Contains the status of the pedometer data request:
     * 
     * - `"success"` - if no error has occurred
     * - If a `aas2/aas2-sensing-service`-specific error has occurred: The `code` attribute of the error.
     * - If an unspecific error has occurred: `"unspecified-error"`
     */
    status: string;

    /** If an error occurred, contains a description of the error; otherwise, `null`. */
    error_message: string | null;

    /**
     * The permission level for location data access:
     * - `"fine"` - If the `fineLocation` permission has been granted.
     * - `"coarse"` - If the `coarseLocation` permission has been granted.
     * 
     * If an error has occurred, the value is `null`.
     */
    permission: string | null;

    /**
     * Contains a timestamp (in epoch millis) of when the location data was collected.
     * If an error has occurred, the value is `null`.
     */
    collected_at: number | null;

    /** 
     * Radius of uncertainty in meters.
     * It contains `null` if an error has occurred or no accuracy value has been returned by the operating system.
     */
    accuracy: number | null;

    /**
     * Latitude in degrees or `null` if an error has occurred.
     */
    latitude: number | null;

    /**
     * Longitude in degrees or `null` if an error has occurred.
     */
    longitude: number | null;


    /**
     * Altitude in meters above the WGS84 reference ellipsoid or `null` if an error has occurred or no altitude value has been returned by the operating system.
     */
    altitude: number | null;

    /**
     * Estimated altitude accuracy in meters or `null` if an error has occurred or no altitude accuracy value has been returned by the operating system.
     */
    altitude_accuracy: number | null;

    /**
     * Speed in meters per second or `null` if an error has occurred or no speed value has been returned by the operating system.
     */
    speed: number | null;

    /**
     * Constructor for the LocationInformation class.
     * @param status - The status of the location data request.
     * @param errorMessage - The error message if an error occurred, otherwise `null`.
     * @param permission - The permission level for location data access, or `null`.
     * @param collectedAt - The timestamp when the location data was collected, or `null`.
     * @param accuracy - The accuracy of the location data, or `null`.
     * @param latitude - The latitude of the location, or `null`.
     * @param longitude - The longitude of the location, or `null`.
     * @param altitude - The altitude of the location, or `null`.
     * @param altitudeAccuracy - The accuracy of the altitude measurement, or `null`.
     */
    constructor(status: string, errorMessage: string | null, permission: string | null, collectedAt: number | null, accuracy: number | null, latitude: number | null, longitude: number | null, altitude: number | null, altitudeAccuracy: number | null, speed: number | null) {
        this.status = status;
        this.error_message = errorMessage;
        this.permission = permission;
        this.collected_at = collectedAt;
        this.accuracy = accuracy;
        this.latitude = latitude;
        this.longitude = longitude;
        this.altitude = altitude;
        this.altitude_accuracy = altitudeAccuracy;
        this.speed = speed;
    }
}