import { Component, OnInit, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Question } from "../Question";
import { QuestionDateModel } from "../../../models/elements/QuestionDateModel";

@Component({
  selector: "app-text-date",
  templateUrl: "./text-date.component.html",
  styleUrls: ["./text-date.component.scss"],
})
export class Date_ extends Question implements OnInit {
  @Input() public loopIndex: number;
  model: QuestionDateModel;
  initDate: any;
  value: any = null;
  showCalendar: boolean = false;
  selectedDateTime: string;
  locale: string = "en";
  presentation: string = "date-time";

  constructor(private translateService: TranslateService) {
    super();
  }

  ngOnInit() {
    this.locale = this.translateService.instant("LESSON.DATE_FORMAT_LAN");
    if (this.model.type == "date") {
      this.presentation = "date";
    } else if (this.model.type == "time") {
      this.presentation = "time";
    }

    this.initDate = this.currentValue;
    this.selectedDateTime = this.initDate;

    // emit null (so that there is an entry for each repetition) or saved answer
    if (!this.currentValue) {
      this.initAnswerArray();
    }
  }

  ngOnDestroy() {
    console.log(
      "destroy pos " + this.model.position + " loop" + this.loopIndex,
    );
    this.model.lesson.removeAnswerRepetition(
      this.model.position,
      this.loopIndex,
    );
  }

  public emitAnswer() {
    if (this.selectedDateTime === undefined || this.model.lesson.loading) {
      return;
    }
    //selected value is date in timezone of user -> has to be in utc
    //example: user selects "30.1.23 0 uhr"
    //value of the picker is "Mon Jan 30 2023 00:00:00 GMT+0100" -> the correct time but shown in timezone of the user
    //we want exactly this time "30.1.23 0 uhr" but saved in utc, so it is the same no matter where it was saved
    //saved answer is: 2023-01-30T00:00:00.000Z
    //for init see comment above
    let answ: Date = null;
    if (this.selectedDateTime) {
      answ = new Date(this.selectedDateTime);
      answ = new Date(answ.getTime() - answ.getTimezoneOffset() * 60000); // Subtract the user's time zone from the selected date time
    }

    // insert technical answer in global array
    this.model.lesson.insertAnswer(this.model.position, this.loopIndex, answ);

    const inserted: Date = this.currentValue;
    if (inserted) {
      this.initDate = inserted;
    }
  }

  initAnswerArray(): void {
    // This function initializes spaces with the value null in the responses array, thus creating
    // slots to store data in them.
    this.model.lesson.insertAnswer(this.model.position, this.loopIndex, null);
  }

  get currentValue(): Date | null {
    return this.model.lesson.getAnswer(this.model.position, this.loopIndex);
  }

  formatDate(utcDate: Date | string): string {
    utcDate = new Date(utcDate); // Convert utcDate to Date if it is a string
    utcDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000); // Add the user's time zone to the selected date time
    switch (this.presentation) {
      case "date-time": {
        return utcDate.toLocaleDateString(this.locale, {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "numeric",
          minute: "2-digit",
          hour12: this.translateService.instant("LESSON.DATE_AMPM") !== "false",
          weekday: "long",
        });
      }
      case "date": {
        return utcDate.toLocaleDateString(this.locale, {
          day: "numeric",
          month: "long",
          year: "numeric",
          weekday: "long",
        });
      }
      case "time": {
        return utcDate.toLocaleString(this.locale, {
          hour: "numeric",
          minute: "2-digit",
          hour12: this.translateService.instant("LESSON.DATE_AMPM") !== "false",
        });
      }
    }
  }

  openDatePicker() {
    this.showCalendar = true;
  }

  closeDatePicker() {
    this.showCalendar = false;
  }
}
